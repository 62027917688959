/* You can add global styles to this file, and also import other style files */
// @import '~bootstrap/scss/bootstrap';

/* Importing Bootstrap SCSS file. */
// @import '~bootstrap/scss/bootstrap';

@import url('https://code.ionicframework.com/ionicons/2.0.1/css/ionicons.min.css');
@import url('https://cdnjs.cloudflare.com/ajax/libs/flag-icon-css/3.3.0/css/flag-icon.min.css');
@import url('https://fonts.googleapis.com/css?family=Source+Sans+Pro:300,400,400i,700');

@import '~admin-lte/plugins/fontawesome-free/css/all.min.css';
@import '~admin-lte/plugins/icheck-bootstrap/icheck-bootstrap.min.css';
@import '~admin-lte/dist/css/adminlte.min.css';

@import 'ngx-toastr/toastr';
@import "~swiper/swiper-bundle.css";

body {
  width: 100vw;
  height: 100vh;
  overflow-x: hidden;
}
body {
  /*background-image: linear-gradient(rgba(255, 255, 255, 0.95), rgba(255, 255, 255, 0.95)), url("./assets/img/hospital-bg.png");
  position: relative;
  background-repeat: repeat;
  background-size: cover; !* Adjust as needed *!
  !* Other styles *!*/
}

::-webkit-scrollbar-track {
  width: 0px;
  background: darkgray;
}

.content {
  padding: 0 0.5rem;
}

// Sidebar menu animation
// Needed for open/close menu animation for menus with child nodes
.nav .nav-item:not(.menu-open) > .ng-trigger-openClose,
.nav .nav-item > .ng-trigger-openClose.ng-animating {
  overflow: hidden;
  display: block;
}

// Override original AdminLTE transform
.nav-sidebar .menu-is-opening > .nav-link i.right,
.nav-sidebar .menu-is-opening > .nav-link svg.right,
.nav-sidebar .menu-open > .nav-link i.right,
.nav-sidebar .menu-open > .nav-link svg.right {
  transform: none;
}
body {
  font-family: 'prompt';
}
